import {
  ComponentRef,
  Connection,
  DeveloperModeStatus,
  PageRef,
  commonConfig,
  gfppOriginStrings,
  ConsentPolicy,
  EventType,
  EventsInterfaceMap,
  PanelHeaderButton,
  ThemeChanged,
  ViewStateChanged,
  GrantApp,
  RevokeApp,
  AppInstalled,
  RemoveAppCompleted,
  ResetWidgetOverrides,
  WidgetPluginAdded,
} from '@wix/editor-platform-sdk-types'

export const eventsFactory: EventsInterfaceMap = {
  appMenuReorder({
    menuId,
    menuItemId,
    parentId,
    newIndex,
  }: {
    menuId: string
    menuItemId: string
    parentId: string
    newIndex: number
  }) {
    return {
      eventType: EventType.appMenuReorder,
      eventPayload: {
        menuId,
        menuItemId,
        parentId,
        newIndex,
      },
    }
  },

  componentDeleted({
    componentRef,
    connections,
  }: {
    componentRef: ComponentRef
    connections: Connection[]
  }) {
    return {
      eventType: EventType.componentDeleted,
      eventPayload: {
        componentRef,
        connections,
      },
    }
  },

  componentGfppClicked({
    id,
    role,
    componentRef,
    controllerRef,
  }: {
    id: string
    role: string
    componentRef: ComponentRef
    controllerRef: ComponentRef
  }) {
    return {
      eventType: EventType.componentGfppClicked,
      eventPayload: {
        id,
        role,
        componentRef,
        controllerRef,
      },
    }
  },

  appActionClicked({actionId}: {actionId: string}) {
    return {
      eventType: EventType.appActionClicked,
      eventPayload: {
        actionId,
      },
    }
  },

  connectedComponentPasted({
    componentRef,
    connection,
    originalComponentRef,
  }: {
    componentRef: ComponentRef
    connection: Connection
    originalComponentRef: ComponentRef
  }) {
    return {
      eventType: EventType.connectedComponentPasted,
      eventPayload: {
        componentRef,
        originalComponentRef,
        connection,
      },
    }
  },

  connectedComponentDuplicated({
    componentRef,
    connection,
    originalComponentRef,
  }: {
    componentRef: ComponentRef
    connection: Connection
    originalComponentRef: ComponentRef
  }) {
    return {
      eventType: EventType.connectedComponentDuplicated,
      eventPayload: {
        componentRef,
        originalComponentRef,
        connection,
      },
    }
  },

  concurrentPanelEdit() {
    return {
      eventType: EventType.concurrentPanelEdit,
      eventPayload: {},
    }
  },

  widgetPasted({
    componentRef,
    originalComponentRef,
  }: {
    componentRef: ComponentRef
    originalComponentRef: ComponentRef
  }) {
    return {
      eventType: EventType.widgetPasted,
      eventPayload: {
        componentRef,
        originalComponentRef,
      },
    }
  },

  widgetDuplicated({
    componentRef,
    originalComponentRef,
  }: {
    componentRef: ComponentRef
    originalComponentRef: ComponentRef
  }) {
    return {
      eventType: EventType.widgetDuplicated,
      eventPayload: {
        componentRef,
        originalComponentRef,
      },
    }
  },

  widgetAdded({
    componentRef,
    originalComponentId,
    widgetId,
  }: {
    componentRef: ComponentRef
    originalComponentId: string
    widgetId: string
  }) {
    return {
      eventType: EventType.widgetAdded,
      eventPayload: {
        componentRef,
        widgetId,
        originalComponentId,
      },
    }
  },

  controllerAdded({
    controllerRef,
    origin,
  }: {
    controllerRef: ComponentRef
    origin: string
  }) {
    return {
      eventType: EventType.controllerAdded,
      eventPayload: {
        controllerRef,
        origin,
      },
    }
  },

  controllerSettingsButtonClicked({
    controllerRef,
    controllerConfig,
    controllerType,
    controllerDisplayName,
  }: {
    controllerRef: ComponentRef
    controllerConfig?: any
    controllerType: string
    controllerDisplayName: string
  }) {
    return {
      eventType: EventType.controllerSettingsButtonClicked,
      eventPayload: {
        controllerRef,
        controllerConfig,
        controllerType,
        controllerDisplayName,
        origin: 'gfpp',
      },
    }
  },

  controllerGfppClicked({
    id,
    controllerRef,
    controllerConfig,
    controllerType,
    controllerDisplayName,
  }: {
    id: string
    controllerRef: ComponentRef
    controllerConfig?: any
    controllerType: string
    controllerDisplayName: string
  }) {
    return {
      eventType: EventType.controllerGfppClicked,
      eventPayload: {
        id,
        controllerRef,
        controllerConfig,
        controllerType,
        controllerDisplayName,
        origin: 'gfpp',
      },
    }
  },

  stateChanged({
    componentRef,
    selectedStateRef,
  }: {
    componentRef: ComponentRef
    selectedStateRef: ComponentRef
  }) {
    return {
      eventType: EventType.stateChanged,
      eventPayload: {
        componentRef,
        selectedStateRef,
      },
    }
  },

  stateAdded({
    componentRef,
    addedStateRef,
  }: {
    componentRef: ComponentRef
    addedStateRef: ComponentRef
  }) {
    return {
      eventType: EventType.stateAdded,
      eventPayload: {
        componentRef,
        addedStateRef,
      },
    }
  },

  stateDuplicated({
    componentRef,
    newDuplicatedStateRef,
  }: {
    componentRef: ComponentRef
    newDuplicatedStateRef: ComponentRef
  }) {
    return {
      eventType: EventType.stateDuplicated,
      eventPayload: {
        componentRef,
        newDuplicatedStateRef,
      },
    }
  },

  pageDeleted({pageRole}: {pageRole: string}) {
    return {
      eventType: EventType.pageDeleted,
      eventPayload: {
        pageRole,
      },
    }
  },

  siteWasPublished() {
    return {
      eventType: EventType.siteWasPublished,
    }
  },

  siteWasFirstSaved({
    instanceId,
    instance,
    metaSiteId,
  }: {
    instanceId: string
    instance: string
    metaSiteId: string
  }) {
    return {
      eventType: EventType.siteWasFirstSaved,
      eventPayload: {
        instanceId,
        instance,
        metaSiteId,
      },
    }
  },

  siteWasSaved({isAutosave}: {isAutosave: boolean}) {
    return {
      eventType: EventType.siteWasSaved,
      eventPayload: {
        isAutosave,
      },
    }
  },

  startConfiguration({
    token,
    initialData,
    origin,
    commonConfig,
  }: {
    token: string
    initialData?: any
    origin?: string
    commonConfig?: commonConfig
  }) {
    return {
      eventType: EventType.startConfiguration,
      eventPayload: {
        token,
        initialData,
        origin,
        commonConfig,
      },
    }
  },

  welcomeSectionMainActionClicked() {
    return {
      eventType: EventType.welcomeSectionMainActionClicked,
      eventPayload: {},
    }
  },

  widgetGfppClicked({
    componentRef,
    id,
    gfppOrigin,
  }: {
    id: string
    componentRef: ComponentRef
    gfppOrigin?: gfppOriginStrings
  }) {
    return {
      eventType: EventType.widgetGfppClicked,
      eventPayload: {
        id,
        componentRef,
        gfppOrigin,
      },
    }
  },

  appUpgradeCompleted({
    vendorProductId,
    cycle,
    instance,
  }: {
    vendorProductId: string
    cycle: number
    instance: string
  }) {
    return {
      eventType: EventType.appUpgradeCompleted,
      eventPayload: {
        vendorProductId,
        cycle,
        instance,
      },
    }
  },

  globalDesignPresetChanged({
    componentRef,
    preset,
  }: {
    componentRef: ComponentRef
    preset: string
  }) {
    return {
      eventType: EventType.globalDesignPresetChanged,
      eventPayload: {
        preset,
        componentRef,
      },
    }
  },

  developerModeChanged({value}: {value: boolean}) {
    return {
      eventType: EventType.developerModeChanged,
      eventPayload: {
        value,
      },
    }
  },

  developerModeStatusChanged({status}: {status: DeveloperModeStatus}) {
    return {
      eventType: EventType.developerModeStatusChanged,
      eventPayload: {
        status,
      },
    }
  },

  /**
   * @deprecated — prefer to listen to `connectedComponentAddedToStage` for notifications about
   * TPA's connected components or to `anyComponentAddedToStage` for notifications about any component
   */
  componentAddedToStage({
    componentType,
    compRef,
  }: {
    componentType: string
    compRef: ComponentRef
  }) {
    return {
      eventType: EventType.componentAddedToStage,
      eventPayload: {
        componentType,
        compRef,
      },
    }
  },

  connectedComponentAddedToStage({
    componentType,
    compRef,
  }: {
    componentType: string
    compRef: ComponentRef
  }) {
    return {
      eventType: EventType.connectedComponentAddedToStage,
      eventPayload: {
        componentType,
        compRef,
      },
    }
  },

  anyComponentAddedToStage({
    componentType,
    compRef,
  }: {
    componentType: string
    compRef: ComponentRef
  }) {
    return {
      eventType: EventType.anyComponentAddedToStage,
      eventPayload: {
        componentType,
        compRef,
      },
    }
  },

  appUpdateCompleted({appDefinitionId}: {appDefinitionId: string}) {
    return {
      eventType: EventType.appUpdateCompleted,
      eventPayload: {
        appDefinitionId,
      },
    }
  },

  componentAnimationChanged({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentAnimationChanged,
      eventPayload: {
        compRef,
      },
    }
  },

  componentDataChanged<T = unknown>({
    compRef,
    previousData,
  }: {
    compRef: ComponentRef
    previousData?: T
  }) {
    return {
      eventType: EventType.componentDataChanged,
      eventPayload: {
        compRef,
        previousData,
      },
    }
  },

  componentDesignChanged({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentDesignChanged,
      eventPayload: {
        compRef,
      },
    }
  },

  componentStyleChanged({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentStyleChanged,
      eventPayload: {
        compRef,
      },
    }
  },

  componentArrangementChanged({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentArrangementChanged,
      eventPayload: {
        compRef,
      },
    }
  },

  componentDragEnded({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentDragEnded,
      eventPayload: {
        compRef,
      },
    }
  },

  componentResizeStarted({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentResizeStarted,
      eventPayload: {
        compRef,
      },
    }
  },

  componentRotateEnded({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentRotateEnded,
      eventPayload: {
        compRef,
      },
    }
  },

  pageBackgroundChanged({pageRef}: {pageRef: PageRef}) {
    return {
      eventType: EventType.pageBackgroundChanged,
      eventPayload: {
        pageRef,
      },
    }
  },

  pageDuplicated({
    originalPageRef,
    duplicatedPageRef,
  }: {
    originalPageRef: PageRef
    duplicatedPageRef: PageRef
  }) {
    return {
      eventType: EventType.pageDuplicated,
      eventPayload: {
        originalPageRef,
        duplicatedPageRef,
      },
    }
  },

  pageAdded({
    pageRef,
    originalPageRef,
  }: {
    pageRef: PageRef
    originalPageRef: PageRef | null
  }) {
    return {
      eventType: EventType.pageAdded,
      eventPayload: {
        pageRef,
        originalPageRef,
      },
    }
  },

  sitePublishedDialogClosed() {
    return {
      eventType: EventType.sitePublishedDialogClosed,
      eventPayload: {},
    }
  },

  switchedFromPreview() {
    return {
      eventType: EventType.switchedFromPreview,
      eventPayload: {},
    }
  },

  mobileTextScaleChanged({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.mobileTextScaleChanged,
      eventPayload: {
        compRef,
      },
    }
  },

  componentCropSaved({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentCropSaved,
      eventPayload: {
        compRef,
      },
    }
  },

  toggleBackToTopButtonOn() {
    return {
      eventType: EventType.toggleBackToTopButtonOn,
      eventPayload: {},
    }
  },

  componentBehaviorChanged({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentBehaviorChanged,
      eventPayload: {
        compRef,
      },
    }
  },

  componentPropsChanged({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.componentPropsChanged,
      eventPayload: {
        compRef,
      },
    }
  },

  switchedToMobileView() {
    return {
      eventType: EventType.switchedToMobileView,
      eventPayload: {},
    }
  },

  switchedToDesktopView() {
    return {
      eventType: EventType.switchedToDesktopView,
      eventPayload: {},
    }
  },

  textEditBoxClosed({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.textEditBoxClosed,
      eventPayload: {
        compRef,
      },
    }
  },

  hideMobileElement({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.hideMobileElement,
      eventPayload: {
        compRef,
      },
    }
  },

  pageVariantSelected({variantId}: {variantId: string}) {
    return {
      eventType: EventType.pageVariantSelected,
      eventPayload: {
        variantId,
      },
    }
  },

  showMobileElement({compRef}: {compRef: ComponentRef}) {
    return {
      eventType: EventType.showMobileElement,
      eventPayload: {
        compRef,
      },
    }
  },

  focusedPageChanged({pageRef}: {pageRef: PageRef}) {
    return {
      eventType: EventType.focusedPageChanged,
      eventPayload: {
        pageRef,
      },
    }
  },

  instanceChanged(instance: string) {
    return {
      eventType: EventType.instanceChanged,
      eventPayload: {
        instance,
      },
    }
  },

  componentSelectionChanged(componentRefs: ComponentRef[]) {
    return {
      eventType: EventType.componentSelectionChanged,
      eventPayload: {
        componentRefs,
      },
    }
  },

  pageRenamed({pageRef, title}: {pageRef: PageRef; title: string}) {
    return {
      eventType: EventType.pageRenamed,
      eventPayload: {
        pageRef,
        title,
      },
    }
  },

  addDynamicPageClicked({
    origin,
    pageRef,
  }: {
    origin: string
    pageRef?: PageRef
  }) {
    return {
      eventType: EventType.addDynamicPageClicked,
      eventPayload: {
        origin,
        pageRef,
      },
    }
  },

  consentPolicyChanged({currentPolicy}: {currentPolicy: ConsentPolicy}) {
    return {
      eventType: EventType.consentPolicyChanged,
      eventPayload: {
        currentPolicy,
      },
    }
  },

  undo({label, params}: {label: string; params?: any}) {
    return {
      eventType: EventType.undo,
      eventPayload: {
        label,
        params,
      },
    }
  },

  redo({label, params}: {label: string; params?: any}) {
    return {
      eventType: EventType.redo,
      eventPayload: {
        label,
        params,
      },
    }
  },

  addElementsCompClicked({
    label,
    isEssential,
    widgetRef,
  }: {
    label: string
    isEssential: string
    widgetRef: ComponentRef
  }) {
    return {
      eventType: EventType.addElementsCompClicked,
      eventPayload: {
        label,
        isEssential,
        widgetRef,
      },
    }
  },

  addElementsAllCompsClicked({widgetRef}: {widgetRef: ComponentRef}) {
    return {
      eventType: EventType.addElementsAllCompsClicked,
      eventPayload: {
        widgetRef,
      },
    }
  },

  addElementsResetClicked({widgetRef}: {widgetRef: ComponentRef}) {
    return {
      eventType: EventType.addElementsResetClicked,
      eventPayload: {
        widgetRef,
      },
    }
  },

  appVisitedInDashboard({visitedAppDefIds}: {visitedAppDefIds: string[]}) {
    return {
      eventType: EventType.appVisitedInDashboard,
      eventPayload: {
        visitedAppDefIds,
      },
    }
  },

  componentAddedToApp({
    widgetRef,
    compRef,
  }: {
    widgetRef: ComponentRef
    compRef: ComponentRef
  }) {
    return {
      eventType: EventType.componentAddedToApp,
      eventPayload: {
        widgetRef,
        compRef,
      },
    }
  },

  documentOperationError({
    methodName,
    error,
  }: {
    methodName: string | undefined
    error: {
      name: string
      message: string
      stack: string
    }
  }) {
    return {
      eventType: EventType.documentOperationError,
      eventPayload: {
        methodName,
        error: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      },
    }
  },

  solveAddWidgetLimitation({
    origin,
    widgetId,
  }: {
    origin: string
    widgetId: string
  }) {
    return {
      eventType: EventType.solveAddWidgetLimitation,
      eventPayload: {
        origin,
        widgetId,
      },
    }
  },

  componentConnected({
    controllerRef,
    compRef,
  }: {
    controllerRef: ComponentRef
    compRef: ComponentRef
  }) {
    return {
      eventType: EventType.componentConnected,
      eventPayload: {
        controllerRef,
        compRef,
      },
    }
  },

  componentDisconnected({
    controllerRef,
    compRef,
  }: {
    controllerRef: ComponentRef
    compRef: ComponentRef
  }) {
    return {
      eventType: EventType.componentDisconnected,
      eventPayload: {
        controllerRef,
        compRef,
      },
    }
  },

  panelHeaderButtonClicked({button}: {button: PanelHeaderButton}) {
    return {
      eventType: EventType.panelHeaderButtonClicked,
      eventPayload: {
        button,
      },
    }
  },

  themeChanged(eventPayload: ThemeChanged['eventPayload']) {
    return {
      eventType: EventType.themeChanged,
      eventPayload,
    }
  },

  viewStateChanged(eventPayload: ViewStateChanged['eventPayload']) {
    return {
      eventType: EventType.viewStateChanged,
      eventPayload,
    }
  },

  grantApp(eventPayload: GrantApp['eventPayload']) {
    return {
      eventType: EventType.grantApp,
      eventPayload,
    }
  },

  revokeApp(eventPayload: RevokeApp['eventPayload']) {
    return {
      eventType: EventType.revokeApp,
      eventPayload,
    }
  },

  resetWidgetOverrides(eventPayload: ResetWidgetOverrides['eventPayload']) {
    return {
      eventType: EventType.resetWidgetOverrides,
      eventPayload,
    }
  },

  widgetPluginAdded(eventPayload: WidgetPluginAdded['eventPayload']) {
    return {
      eventType: EventType.widgetPluginAdded,
      eventPayload,
    }
  },

  /** @internal */
  appInstalled(eventPayload: AppInstalled['eventPayload']) {
    return {
      eventType: EventType.appInstalled,
      eventPayload,
    }
  },

  /** @internal */
  removeAppCompleted(eventPayload: RemoveAppCompleted['eventPayload']) {
    return {
      eventType: EventType.removeAppCompleted,
      eventPayload,
    }
  },
}
